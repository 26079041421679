
import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import RightArrow from "../components/right-arrow"

function BlogIndexPage({ location, data }) {

  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location}>
        <Seo
          title="eXtreme Automation Blog"
        />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location}>
      <Seo
        title="eXtreme Automation Blog"
      />
      <section className="mt-8 lg:mt-18 mb-20">
        <h1 className="hidden">Blog</h1>
        <ol className="space-y-13">
          {posts.map((post, postIndex) => {
            const title = post.frontmatter.title || post.frontmatter.slug || post.fields.slug
            return (
              <li key={postIndex}>
                <article className={postIndex > 0 ? "border-t-2 border-dashed border-t-sky-900 pt-13" : ""}>
                  {post.frontmatter.tags ?
                    <div className="hidden md:flex gap-0.5 md:gap-2" >
                      {post.frontmatter.tags.map((tag, tagIndex) => {
                        return (
                          <div key={tagIndex} className="flex-none cursor-pointer border-2 border-gray-100 px-4 py-2 text-xxxs font-bold uppercase text-gray-500 duration-200 before:content-['#'] hover:bg-sky-900 hover:text-white">
                            {tag}
                          </div>
                        )
                      })}
                    </div> : <></>
                  }
                  <header>
                    <h2 className="mt-4">
                      <Link to={post.frontmatter.slug || post.fields.slug}><span>{title}</span></Link>
                    </h2>
                    <small>{post.frontmatter.date}</small>
                  </header>
                  <section className="mt-4 text-black">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                    />
                  </section>
                  <div className="flex flex-row mt-12 font-bold uppercase place-content-end">
                    <Link className="flex items-center text-sm lg:text-lg gap-x-1 md:gap-x-2 hover:text-sky-900" to={post.frontmatter.slug || post.fields.slug}>
                      Read more
                      <RightArrow />
                    </Link>
                  </div>
                </article>
              </li>
            )
          })}
        </ol>
      </section>
    </Layout>
  )
}

export default BlogIndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          slug
          tags
        }
      }
    }
  }
`
